<template>
<!-- 资金往来款账户 -->
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <span>
      <!--banksclass 1-表示收付款账户  2表示股东往来款账户 3表示往来款项账户-->
      <sfbanks-select
        @select-change="sfbanksselect"
        placeholder="请选择账户"
        banksclass="3"
      ></sfbanks-select>
    </span>
  </div>
  <el-table :data="tableData" border style="width: 100%" height="100%">
    <el-table-column prop="transaction_time" sortable label="时间" width="180" />
    <el-table-column prop="income"  label="收入" width="140" :formatter="toThousands"/>
    <el-table-column prop="expenditure"  label="支出" width="140" :formatter="toThousands"/>
    <el-table-column prop="balance" label="余额" width="140" :formatter="toThousands"/>
    <el-table-column prop="detaied_text" label="摘要" width="180" />
     <el-table-column prop="account_name" label="对方名称" width="200" />
    <el-table-column prop="bank_no" label="收款账号" width="200" />
    <el-table-column prop="bank_name" label="对方开户行名称" width="180" />
    <el-table-column prop="fk_request_id_no" label="对应付款申请单号" width="280" />

  </el-table>
</template>

<script>
import axios from "axios";
//import { ElMessageBox } from "element-plus";
export default {
  data() {
    return {
      tableData:[]
    };
  },
  methods: {
    sfbanksselect(value) {
      console.log(value.id);
       this.loadbanksinfo(value.id)
    },
    //加载账户流水  参数banksid  为账户ID
    loadbanksinfo(banksid){
       axios
        .get("admin/SfBanksDetaied", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            sf_banks_id: banksid
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******", response.data, "******");
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
        //格式化数字
    toThousands(row, column, cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
  },
};
</script>